import React, { useEffect } from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
const ShopStyles = styled.div`
  .sprd-header__title {
    visibility: hidden;
  }
`

const Shop = () => {
  useEffect(() => {
    window.spread_shop_config = {
      shopName: "majorleagueeating",
      locale: "us_US",
      prefix: "https://shop.spreadshirt.com",
      baseId: "myShop",
      swipeMenu: false,
    }
    const tag = document.createElement("script")
    tag.async = false
    tag.type = "text/javascript"
    tag.src =
      "https://shop.spreadshirt.com/shopfiles/shopclient/shopclient.nocache.js"
    document.body.appendChild(tag)
  }, [])
  return (
    <Layout>
      <ShopStyles>
        <div id="myShop">
          <a href="https://shop.spreadshirt.com/majorleagueeating">
            majorleagueeating
          </a>
        </div>
      </ShopStyles>
    </Layout>
  )
}

export default Shop
